export const BOUND_TIMER = 500;

export const SINGAPORE_CC = 'sg';
export const SINGAPORE_DIAL_CODE = '65';

export const ID_LENGTH = 24;
export const LOADING_TEXT = 'Loading...';

export const DEFAULT_PREVIEW_TEXT = 'Click the Edit button to add descriptive text to the website landing page.';

export const VOUCHER_REDEEM_INPUT_REASON_MAX_LENGTH = 120;